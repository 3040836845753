(function () {
    'use strict';

    class LoginCtrl {
        constructor($state, $window, UserService, AuthApi, startState, permissions, $uibModal, CustomerMapDataService, $http, apiBasePath, ModuleService, entryFlowByAccount) {
            let vm = this;
            vm.UserService = UserService;
            vm.canImpersonate = () => UserService.hasPerm(permissions.ADMIN_CAN_IMPERSONATE);
            vm.user = {
                username: '',
                password: ''
            };
            vm.error = null;
            vm.isGwfUrl = window.origin.includes('mitgliederportal.frankenwein-gwf.de');
            vm.isDwUrl = window.origin.includes('staging.wachau');

            vm.login = () => {
                vm.error = null;
                AuthApi.save({
                    action: 'login'
                }, vm.user, (data) => {
                    if (UserService.validateToken(data.accessToken, [
                        permissions.BASE_CAN_USE,
                        permissions.BASE_CAN_DO_MANAGEMENT
                    ])) {
                        UserService.storeAuthToken(data.accessToken, data.refreshToken);
                        UserService.storeSessionConfiguration(data);
                        if (vm.isGwfUrl) {
                            window.location.assign('https://mitgliederportal.frankenwein-gwf.de/portal');
                        } else {
                            $state.go(startState);
                        }
                    } else {
                        vm.error = 'permissionError';
                        vm.logout(data.accessToken);
                    }
                }, () => {
                    vm.error = 'loadingError';
                });
            };
            vm.logout = (accessToken) => {
                // we need to use $http to do the request here as we need to manually set the Auth
                // header because it is possible that this method will get called right after login
                // if the token does not contain all necessary permissions. In this case the UserService
                // is not setup with the access token which means the token interceptor cannot set the
                // access token on the request.
                $http.post(apiBasePath + 'auth/logout', {}, {
                    headers: {
                        Auth: accessToken || UserService.getAuthToken().accessToken,
                        IGNORE_INTERCEPTOR: true
                    }
                }).finally(() => {
                    $http.get('bonitur/invalidateSession').then(function (response) {
                        console.log(response);
                    });
                    UserService.clearAuthToken();
                    CustomerMapDataService.invalidateCache();
                    ModuleService.invalidateCache();
                    $state.go('login');
                });
            };

            vm.goToSettings = () => {
                $state.go('settings');
            };

            vm.doImpersonate = () => {
                let popup = $uibModal.open({
                    controller: 'ImpersonationPopupCtrl',
                    controllerAs: 'impersonation',
                    templateUrl: 'auth/impersonation/views/impersonation-popup.tpl.html'
                });
                popup.result.then(customer => {
                    AuthApi.save({
                        action: 'impersonate',
                        customerId: customer.id
                    }, {}, token => {
                        UserService.startImpersonation(token.accessToken);
                        CustomerMapDataService.invalidateCache();
                        ModuleService.invalidateCache();
                        reloadState();
                    });
                });
            };

            vm.stopImpersonate = () => {
                UserService.stopImpersonation();
                CustomerMapDataService.invalidateCache();
                ModuleService.invalidateCache();
                reloadState();
            };

            vm.showHelp = () => {
              if (UserService.user.accountId !== 9) {
                  window.open('https://kb.vy-c.com', '_blank');
              } else {
                  window.userflow.identify(UserService.user.id, {
                      email: UserService.user.email,
                  });
                  const flowId = entryFlowByAccount.get('' + UserService.user.accountId);
                  if (flowId) {
                      window.userflow.start(flowId);
                  }
              }
            };

            function reloadState() {
                let params = angular.copy($state.params);
                // We want to make sure we always switch to the first page of a list,
                // otherwise there might be the case where we switch to a page that has
                // no data and no form controls which would probably confuse the user.
                if (angular.isDefined(params.page)) {
                    params.page = 1;
                }
                $state.transitionTo($state.current, params, {
                    reload: true, inherit: false
                });
            }
        }
    }

    LoginCtrl.$inject = ['$state', '$window', 'UserService', 'AuthApi', 'startState', 'permissions', '$uibModal', 'CustomerMapDataService', '$http', 'apiBasePath', 'ModuleService', 'entryFlowByAccount'];

    /**
     * @ngdoc object
     * @name auth.login.controller:LoginCtrl
     *
     * @description
     *
     */
    angular
        .module('auth.login')
        .controller('LoginCtrl', LoginCtrl);
}());
