(function () {
    'use strict';

    class OrdersListCtrl {
        constructor($q, $state, $stateParams, $uibModal, $filter, OrderTypeHelper, translateFilter, CrudApi, CustomerMapDataService, orderStatus, UserService, permissions, PoiHelper, siteEntities, CustomerMapDataTemporaryService) {
            let vm = this,
                promises = {},
                requestArg = {},
                filtermap = {
                    hasprogress: 'ORDER_FILTERS_ACTIVE',
                    overdue: 'ORDER_FILTERS_OVERDUE',
                    finished: 'ORDER_FILTERS_FINISHED'
                };

            vm.mapId = 'orders-list-map-id';

            // Initialize some variables
            vm.itemsPerPage = 20;
            vm.maxSize = 10;
            vm.page = $stateParams.page || 1;
            vm.sorter = $stateParams.sorter || 'deadlineDate';
            vm.dir = $stateParams.dir || 'desc';
            vm.filter = $stateParams.filter || '';
            vm.message = $stateParams.message;
            vm.orderStatus = orderStatus;
            vm.typeId = $stateParams.typeId;
            vm.accountId = UserService.user.accountId;

            // special function for handling vineyard clicks on the map to ignore clicks on temporary vineyards
            vm.onVineyardClick = onVineyardClick;

            vm.radioModel = $stateParams.case ? translateFilter(filtermap[$stateParams.case]) : translateFilter('ORDER_FILTERS_ALL');

            requestArg = {
                entity: 'orders',
                sortBy: vm.sorter,
                sortDir: vm.dir,
                filter: $stateParams.filter,
                page: vm.page,
                perPage: vm.itemsPerPage,
                typeId: vm.typeId
            };

            if ($stateParams.case === 'overdue') {
                angular.merge(requestArg, {
                    overdue: 1,
                    status: [orderStatus.NONE, orderStatus.STARTED, orderStatus.PAUSED]
                });
            } else if ($stateParams.case === 'hasprogress') {
                angular.merge(requestArg, {status: [orderStatus.STARTED, orderStatus.PAUSED]});
            } else if ($stateParams.case === 'finished') {
                angular.merge(requestArg, {status: orderStatus.FINISHED});
            }

            // Api Call
            promises.items = CrudApi.query(requestArg, (data, headers, status) => {
                vm.total = headers()['x-total-count'];
                if (status === 204) {
                    vm.message = 'noData';
                }
            }, () => {
                vm.message = 'loadingError';
            }).$promise;

            // Fetch order types
            promises.orderTypes = CrudApi.query({
                entity: 'orderTypes'
            }).$promise;

            // Add submenu items
            vm.submenu = {
                items: [{
                    type: 'button',
                    text: 'BUTTON_ADD_MULTIVINEYARD_ORDER',
                    click: () => {
                        $state.go('orders.create', {type: 'regular'});
                    }
                },
                    {
                        type: 'search',
                        model: vm.filter,
                        position: 'right',
                        change: (filter) => {
                            $state.go($state.current.name, {
                                page: 1,
                                filter: filter
                            }, {
                                reload: true
                            });
                        }
                    },
                    {
                        type: 'uib-btn-radio',
                        position: 'right',
                        tooltip: {
                            label: {
                                filterFinished: 'TOOLTIP_ORDER_FILTERS_FINISHED',
                                left: 'TOOLTIP_ORDER_FILTERS_OVERDUE',
                                middle: 'TOOLTIP_ORDER_FILTERS_ACTIVE',
                                right: 'TOOLTIP_ORDER_FILTERS_ALL'
                            }
                        },
                        text: {
                            filterFinished: 'ORDER_FILTERS_FINISHED',
                            left: 'ORDER_FILTERS_OVERDUE',
                            middle: 'ORDER_FILTERS_ACTIVE',
                            right: 'ORDER_FILTERS_ALL'
                        },
                        values: {
                            filterFinished: ['finished'],
                            left: ['overdue'],
                            middle: ['hasprogress']
                        },
                        radioModel: vm.radioModel,
                        click: (args) => {
                            vm.case = args;
                            vm.filter = '';

                            requestArg = {
                                entity: 'orders',
                                sortBy: vm.sorter,
                                sortDir: vm.dir,
                                filter: $stateParams.filter,
                                page: 1,
                                perPage: vm.itemsPerPage,
                                case: vm.case,
                                type: vm.typeId
                            };
                            $state.go($state.current.name, requestArg);
                        }
                    }]
            };

            if (UserService.hasPerm(permissions.BASE_CAN_CREATE_FINISHED_ORDER)) {
                vm.submenu.items.push(
                    {
                        type: 'button',
                        text: 'order.finished.createButton',
                        click: () => {
                            $state.go('orders.create', {type: 'finished'});
                        }
                    });
            }

            vm.isLoading = true;

            // we don't need to wait for the global pois, we just draw them when they are loaded
            CrudApi.query({
                entity: 'poi',
                onlyGlobal: true
            }, pois => {
                let iconPromises = {};
                pois.forEach(poi => iconPromises[poi.id] = PoiHelper.createIconFromPoi(poi));
                $q.all(iconPromises).then(iconData => vm.iconData = iconData);
            });

            // Get vineyards
            promises.vineyards = CrudApi.query({
                entity: siteEntities
            }).$promise;

            // Get all businesses to resolve ids
            promises.businesses = CrudApi.query({
                entity: 'businesses'
            }).$promise;

            promises.helperReady = OrderTypeHelper.ready();

            // Fetch mapdata of all vineyards
            promises.mapData = CustomerMapDataService.getCustomerMapsData();
            promises.mapDataTemporary = CustomerMapDataTemporaryService.getCustomerMapsData();

            // Resolve all promises
            $q.all(promises).then((data) => {
                const siteIds = {};

                data.orderTypes.forEach((type) => {
                    // Check if the user can delete a task of the given type
                    type.canDelete = UserService.hasPerm(type.permissionDelete);
                    type.canCopy = UserService.hasPerm(type.permissionCreate);
                    // Prepare labels
                    type.labelProcessed = OrderTypeHelper.getLabel(type);
                    type.groupLabel = OrderTypeHelper.getGroupLabel(type);
                });

                vm.items = data.items;
                vm.orderTypes = data.orderTypes;
                vm.vineyards = data.vineyards;
                vm.businesses = data.businesses;
                vm.isLoading = false;
                // we need to copy the map data object because we might end up modifying it and the
                // cache service does not guarantee that we will be the only caller that gets the
                // same object from it
                vm.mapData = angular.extend({}, data.mapData);

                vm.submenu.items.push(
                    {
                        type: 'dropdown',
                        position: 'right',
                        model: vm.typeId,
                        values: vm.orderTypes.filter(type => type.groupPosition !== 0),
                        addPlaceholderOption: true,
                        change: (orderTypeId) => {
                            $state.go($state.current.name, {
                                page: 1,
                                message: null,
                                typeId: orderTypeId
                            });
                        }
                    });

                // the order list might contain orders with temporary vineyards, we want to make
                // sure we add the geometry of those vineyards to the mapData
                vm.items.forEach(item => {
                    for (let i = 0; i < item.siteIds.length; i++) {
                        const siteId = item.siteIds[i];
                        siteIds[siteId] = true;
                    }
                });
                Object.keys(siteIds).forEach(siteId => {
                    if (data.mapDataTemporary.hasOwnProperty(siteId)) {
                        vm.mapData[siteId] = data.mapDataTemporary[siteId];
                        vm.mapData[siteId].isTemporary = true;
                    }
                });
            }, () => {
                vm.message = 'loadingError';
            });

            // To hove the related vineyards according to their status if available
            vm.rowHover = function (item, highlight) {
                vm.selectedVineyards = {};

                if (highlight) {
                    angular.forEach(item.siteIds, (vineyardId) => {
                        vm.selectedVineyards[vineyardId] = true;
                    });
                }
            };

            // Pagination method
            vm.paginate = () => {
                $state.go($state.current.name, {
                    page: vm.page,
                    message: null
                });
            };

            // Sorting method
            vm.sort = (sorter) => {
                $state.go($state.current.name, {
                    sorter: sorter,
                    dir: vm.dir === 'desc' ? 'asc' : 'desc',
                    message: null
                });
            };

            vm.getVineyardColumnLabel = (siteIds) => {
                if (!angular.isArray(siteIds) || !siteIds.length) {
                    return translateFilter('ORDER_NO_VINEYARDS_ASSIGNED');
                } else if (siteIds.length === 1) {
                    const vineyardId = siteIds[0];
                    for (let i = 0; i < vm.vineyards.length; i++) {
                        const vineyard = vm.vineyards[i];
                        if (vineyard.id === vineyardId) {
                            return vineyard.label;
                        }
                    }
                    return translateFilter('ORDER_UNKNOWN_VINEYARD');
                }
                return siteIds.length;
            };

            vm.orderVisibilityModeIcon = (item) => {
                if (item.orderVisibilityMode === 'ACCOUNT') {
                    return 'images/orderReadWrite.svg';
                } else if (item.orderVisibilityMode === 'ACCOUNT_READ_ONLY') {
                    return 'images/orderReadOnly.svg';
                } else {
                    return 'images/orderTenant.svg';
                }
            };

            // To return a string containing information about an order status
            vm.progressValueToDisplay = (item) => {
                let stringToReturn = $filter('number')(item.areaDone, 2) + ' / ' + $filter('number')(item.areaTotal, 2) + ' ha ';

                stringToReturn += '(';
                stringToReturn += $filter('number')(item.areaGrossTotal, 2);
                stringToReturn += ' ha) ';

                stringToReturn += '(';

                if (item.status === orderStatus.PAUSED) {
                    stringToReturn += translateFilter('ORDER_STATE_PAUSED');
                } else if (item.status === orderStatus.STARTED ) {
                    stringToReturn += translateFilter('ORDER_STATE_ACTIVE');
                } else if (item.status === orderStatus.FINISHED) {
                    stringToReturn += translateFilter('ORDER_STATE_FINISHED');
                } else if (item.status === orderStatus.NONE && item.progress === 0) {
                    stringToReturn += translateFilter('ORDER_STATE_NOT_STARTED');
                } else {
                    stringToReturn += translateFilter('ORDER_STATE_IN_PROGRESS');
                }

                stringToReturn += ')';
                return stringToReturn;
            };

            vm.copy = (item) => {
                let CrudApiCopy = CrudApi.newInstance(
                    {
                        copy: {
                            method: 'PUT',
                            ignoreSpinner: true,
                        }
                    });
                let promise = CrudApiCopy.copy({
                    entity: 'orders',
                    command: 'copy',
                    id: item.id
                }, {id: item.id}).$promise;
                promise.then((data) => {
                    $state.go('orders.edit', {
                        id: data.id,
                        type: data.cyclicOrderId === null ? 'regular' : 'cyclic',
                        copied: true,
                    });
                });
            };

            // Delete Popup
            vm.delete = (item) => {
                let popup = $uibModal.open({
                    controller: 'OrdersDeleteCtrl',
                    controllerAs: 'delete',
                    templateUrl: 'parcels/edit/orders/delete/views/delete.tpl.html',
                    resolve: {
                        item: () => {
                            return item;
                        },
                        type: () => {
                            return item.cyclicOrderId === null ? 'regular' : 'cyclic';
                        }
                    }
                });
                popup.result.then(() => {
                    CrudApi.delete({
                        entity: 'orders',
                        id: item.id,
                        lastModified: item.lastModified
                    }, (data) => {
                        vm.message = data.messageCode;
                        // Calculate Which page to go after deleting record
                        let pageToGo = 1,
                            pageToGoCalculate = (vm.total - 1) / vm.itemsPerPage;

                        if (pageToGoCalculate % 1 === 0) {
                            pageToGo = vm.page - 1;
                        } else {
                            pageToGo = vm.page;
                        }

                        // stay on same page if it is not the last one
                        if (vm.total / vm.itemsPerPage > vm.page) {
                            pageToGo = vm.page;
                        }

                        vm.page = pageToGo;

                        requestArg.page = pageToGo;
                        vm.items = CrudApi.query(requestArg, (data, headers, status) => {
                            vm.total = headers()['x-total-count'];

                            if (status === 204) {
                                vm.message = 'noData';
                            }

                            // To set the proper page in url
                            $state.go($state.current.name, {
                                page: vm.page
                            });
                        });
                    }, (response) => {
                        if (response.status === 410) {
                            vm.message = 'entityHasChanged';
                        } else if (response.status === 422) {
                            vm.message = response.data.messageCode;
                        } else {
                            vm.message = 'deletingError';
                        }
                    });
                });
            };

            function onVineyardClick(vineyardId) {
                if (vm.mapData && vm.mapData[vineyardId] && !vm.mapData[vineyardId].isTemporary) {
                    $state.go('parcels.edit.orders.list', {
                        id: vineyardId
                    });
                }
            }
        }
    }

    OrdersListCtrl.$inject = ['$q', '$state', '$stateParams', '$uibModal', '$filter', 'OrderTypeHelper', 'translateFilter', 'CrudApi', 'CustomerMapDataService', 'orderStatus', 'UserService', 'permissions', 'PoiHelper', 'siteEntities', 'CustomerMapDataTemporaryService'];

    /**
     * @ngdoc object
     * @name orders.list.controller:ListCtrl
     *
     * @description
     *
     */
    angular
        .module('orders.list')
        .controller('OrdersListCtrl', OrdersListCtrl);
}());
