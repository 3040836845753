<div class="cyclic-orders-list">
    <span class="btn btn-link" ng-click="cyclic.loadData()">
        {{'TOOLTIP_LOAD_DATA' | translate }}
    </span>
	<div class="table-responsive" ng-if="cyclic.items.length">
		<table class="table table-bordered table-hover">
		  <thead>
		    <th ng-click="cyclic.sort('label')">
		      {{'CYCLIC_ORDER_LABEL' | translate}}
		      <span class="glyphicon pull-right text-muted"
		            ng-if="cyclic.sorter === 'label'"
		            ng-class="{
		              'glyphicon-chevron-down': cyclic.dir === 'asc',
		              'glyphicon-chevron-up': cyclic.dir === 'desc'
		            }"></span>
		    </th>
		    <th ng-click="cyclic.sort('firstDeadlineDate')">
		      {{'CYCLIC_ORDER_FIRST_DEADLINE_DATE' | translate}}
		      <span class="glyphicon pull-right text-muted"
		            ng-if="cyclic.sorter === 'firstDeadlineDate'"
		            ng-class="{
		              'glyphicon-chevron-down': cyclic.dir === 'asc',
		              'glyphicon-chevron-up': cyclic.dir === 'desc'
		            }"></span>
		    </th>
		    <th ng-click="cyclic.sort('creationGap')">
		      {{'CYCLIC_ORDER_CREATION_GAP' | translate}}
		      <span class="glyphicon pull-right text-muted"
		            ng-if="cyclic.sorter === 'creationGap'"
		            ng-class="{
		              'glyphicon-chevron-down': cyclic.dir === 'asc',
		              'glyphicon-chevron-up': cyclic.dir === 'desc'
		            }"></span>
		    </th>
		    <th>{{'CYCLIC_ORDER_REPETITION_INTERVAL' | translate}}</th>
		    <th>{{'DELETE' | translate}}</th>
		  </thead>
		  <tbody>
		    <tr
		    ui-sref="orders.edit({
	          id: item.id,
	          v: item.siteIds,
	          type: 'cyclic'
	        })"
		    ng-repeat="item in cyclic.items">
		      <td>{{item.label}}</td>
		      <td>{{item.firstDeadlineDate | date}}</td>
		      <td>{{item.creationGap}}</td>
		      <td>{{item.repetitionInterval}} {{item.repetitionUnit | uppercase | translate}}</td>
		      <td>
		        <button class="btn btn-link"
		                ng-click="$event.stopPropagation(); cyclic.delete(item)">
		          <span class="glyphicon glyphicon-trash"></span>
		        </button>
		      </td>
		    </tr>
		  </tbody>
		</table>
	</div>
	<ul uib-pagination
			total-items="cyclic.total"
      ng-if="cyclic.total > cyclic.itemsPerPage"
      ng-model="cyclic.page"
      items-per-page="cyclic.itemsPerPage"
      ng-change="cyclic.paginate()"
      max-size="cyclic.maxSize"
      boundary-links="true"
      previous-text="&lsaquo;"
      next-text="&rsaquo;"
      first-text="&laquo;"
      last-text="&raquo;">
  </ul>
</div>
