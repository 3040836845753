<ng-form class="form-group"
         name="additionalForm"
         style="display: block;"
         ng-controller="OrderTypeMaterialCtrl as additional">
    <ng-form class="form-group"
             ng-class="{
            'has-error': materialForm.$invalid
          }"
             name="materialForm"
             style="display: block;">
        <label class="control-label">{{'material.label' | translate}}</label>
        <ui-select multiple ng-disabled="!edit.isCreate && !edit.canEdit" ng-model="edit.additionalData.articles"
                   theme="bootstrap" style="margin-bottom: 5px;" ng-if="additional.articles"
                   on-select="additional.sanitizeSelection($item, edit.additionalData.articles)">
            <ui-select-match placeholder="  {{'ui.select.placeholder' | translate}}">
                {{$item.article.label}}
            </ui-select-match>
            <ui-select-choices
                    repeat="article in additional.getAvailableArticles($select.search, edit.additionalData.articles) | orderBy:'label'">
                {{article.label}}
            </ui-select-choices>
        </ui-select>
        <div class="row"
             style="margin-bottom: 5px;"
             ng-repeat-start="article in edit.additionalData.articles">
            <div class="col-xs-6">
                <input class="form-control"
                       type="text"
                       readonly
                       ng-model="article.article.label"/>
            </div>
            <div class="col-xs-3">
                <div class="input-group">
                    <input class="form-control"
                           type="number"
                           placeholder="{{'ui.amount.placeholder' | translate}}"
                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                           ng-disabled="!edit.isCreate && !edit.canEdit"
                           required
                           ng-model="article.amount"
                           ng-change="additional.amountTotalToPerHa(article, edit.additionalData.areaMethod)"/>
                    <span class="input-group-addon"
                          style="min-width: 40px;">{{article.article.unit}}</span>
                </div>
            </div>
            <div class="col-xs-3">
                <div class="input-group">
                    <input class="form-control"
                           type="number"
                           placeholder="{{'ui.amount.placeholder' | translate}}"
                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                           ng-disabled="!edit.isCreate && !edit.canEdit"
                           required
                           ng-model="article.amountPerHa"
                           ng-change="additional.amountPerHaToTotal(article, edit.additionalData.areaMethod)"/>
                    <span class="input-group-addon"
                          style="min-width: 40px;">{{article.article.unit}} / {{'unit.hectare.short' | translate}}</span>
                </div>
            </div>
        </div>
        <div ng-repeat-end></div>
        <p class="help-block"
           ng-messages="materialForm.$error">
            <span ng-message="pattern">{{'material.amount.error.input.fractionSize' | translate}}</span>
            <span ng-message="required">{{'ui.amount.error.required' | translate}}</span>
        </p>
    </ng-form>
    <!-- AREA METHOD -->
    <div class="form-group"
         ng-class="{
               'has-error': additionalForm.areaMethod.$invalid
             }">
        <label class="control-label">{{'PLANT_PROTECTION_AREA_METHOD' | translate}}</label>
        <select class="form-control"
                name="areaMethod"
                ng-model="edit.additionalData.areaMethod"
                ng-disabled="!edit.isCreate && !edit.canEdit"
                ng-change="additional.updateAmounts(edit.additionalData.areaMethod)"
                required>
            <option value="gross">
                {{'PLANT_PROTECTION_AREA_METHOD_BRUTTO' | translate}}
            </option>
            <option value="net">
                {{'PLANT_PROTECTION_AREA_METHOD_NETTO' | translate}}
            </option>
        </select>
        <span class="help-block">
            <span ng-class="{
              'strong': edit.additionalData.areaMethod === 'gross'
            }">
              {{'PLANT_PROTECTION_AREA_METHOD_BRUTTO' | translate}}<br />
              <ul>
                <li>{{'PLANT_PROTECTION_PARCELS_BRUTTO' | translate}}</li>
                <li>= {{edit.areaSums.brutto | number:4}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</li>
              </ul>
            </span>
            <span ng-class="{
              'strong': edit.additionalData.areaMethod === 'net'
            }">
              {{'PLANT_PROTECTION_AREA_METHOD_NETTO' | translate}}<br />
              <ul>
                <li>{{'PLANT_PROTECTION_PARCELS_NETTO' | translate}}</li>
                <li>= {{edit.areaSums.netto | number:4}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</li>
              </ul>
            </span>
        </span>
    </div>
    <span class="material-perSite-headline">{{'material.amount.perSite.headline' | translate}}</span>
    <div class="alert alert-info"
         ng-if="plantProtectionForm.$invalid">
        {{'material.amount.perSite.help' | translate}}
    </div>
    <div class="form-control-static" ng-if="materialForm.$valid">
        <uib-accordion close-others="false">
            <div uib-accordion-group class="panel-default" ng-repeat="site in edit.selectedSites">
                <uib-accordion-heading>
                    <span class="accordion-heading-size">{{site.label}}</span>
                </uib-accordion-heading>
                <div class="table-responsive">
                    <table class="table table-bordered table-striped no-margin-bottom">
                        <tr ng-repeat="article in edit.additionalData.articles">
                            <th>{{ article.article.label }}</th>
                            <td class="text-right">
                                {{ additional.calcAmountOfArticle(article, site, edit.additionalData.areaMethod) | number:4 }}
                                {{ article.article.unit | translate}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </uib-accordion>
    </div>
</ng-form>
