<sub-menu items="edit.submenu.items" form="form" ng-if="edit.canEdit"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<div class="customer-edit base-layout">
    <div class="base-layout-default">
        <main-message message="edit.message" fluid="'nested'"></main-message>
        <div class="tag-content" ng-if="edit.customer.account_id == 20">
            <div class="tag-pill" ng-repeat="tag in edit.customer.tags">
                <span>{{('customer.tags.' + tag) | translate}}</span>
            </div>
        </div>
        <ng-form name="form">
            <uib-tabset active="active">
                <uib-tab heading="{{'ADMIN_CUSTOMERS_TAB_BASE' | translate}}">
                    <div class="alert alert-warning">{{'customer.reloadMapsNotice' | translate}}</div>
                    <div class="checkbox" style="margin-bottom: 15px">
                        <label style="font-weight: 700">
                            <input type="checkbox"
                                   ng-readonly="!edit.canEdit"
                                   ng-model="edit.customer.isActive"/>
                            {{'customer.isActive.label' | translate}}
                        </label>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.label.$invalid || customer.messages.errorCustomerExists
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_LABEL' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.label" name="label" required
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.customIdentifier.$invalid
            }">
                        <label class="control-label"
                               ng-if="!edit.domainWachau">{{'ADMIN_CUSTOMERS_CUSTOM_IDENTIFIER' | translate}}</label>
                        <label class="control-label" ng-if="edit.domainWachau">Mitgliedsnummer</label>
                        <input class="form-control" type="text" ng-model="edit.customer.customIdentifier"
                               name="customIdentifier" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.taxNumber.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_TAX_NUMBER' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.taxNumber"
                               name="taxNumber" ng-readonly="!edit.canEdit"/>
                    </div>
                    <hr/>

                    <div class="form-group" ng-class="{
            'has-error': form.formOfAddress.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_FORM_OF_ADDRESS' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.formOfAddress"
                               name="formOfAddress"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.title.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_TITLE' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.title" name="title"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.firstname.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_FIRSTNAME' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.firstname" name="firstname"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.lastname.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_LASTNAME' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.lastname" name="lastname"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.additionalTitle.$invalid
            }">
                        <label class="control-label">Titel hinten</label>
                        <input class="form-control" type="text" ng-model="edit.customer.additionalTitle"
                               name="additionalTitle"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.personalSalutation.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_PERSONAL_SALUTATION' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.personalSalutation"
                               name="personalSalutation"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.position.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_POSITION' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.position" name="position"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_BIRTHDAY' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   uib-datepicker-popup="dd.MM.yyyy"
                                   ng-model="edit.customer.birthday"
                                   readonly="readonly"
                                   is-open="edit.birthdayOpen"
                                   type="text"
                                   show-button-bar="false"
                                   datepicker-options="{
                                datepickerMode: 'day',
                                minMode: 'day'
                    }"/>
                            <div class="input-group-btn">
                                <button class="btn btn-default"
                                        ng-click="edit.birthdayOpen = !edit.birthdayOpen">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.company.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_COMPANY' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.company" name="company"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.companyIdentifier.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_COMPANY_IDENTIFIER' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.companyIdentifier"
                               name="companyIdentifier"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.companyIdentifier.$invalid
            }">
                        <label class="control-label">Homepage</label>
                        <input class="form-control" type="text" ng-model="edit.customer.homepage" name="homepage"
                               onchange=""
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{'has-error': form.supplierIdentifier.$invalid}"
                         ng-if="!edit.domainWachau">
                        <label class="control-label"
                               ng-if="!edit.domainWachau">{{'ADMIN_CUSTOMERS_ORGANIC_FARMING' | translate}}</label>
                        <label class="control-label" ng-if="edit.domainWachau">Bewirtschaftungsart</label>
                        <div class="radio" style="margin-bottom: 20px; padding-left: 10px;">
                            <label>
                                <input type="radio"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.organicStatus"
                                       value="YES"/>
                                {{'ADMIN_CUSTOMERS_ORGANIC' | translate}}
                            </label>
                        </div>
                        <div class="radio" style="margin-bottom: 20px; padding-left: 10px;">
                            <label>
                                <input type="radio"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.organicStatus"
                                       value="TRANSITION"/>
                                {{'ADMIN_CUSTOMERS_ORGANIC_CONVERSION' | translate}}
                            </label>
                        </div>
                        <div class="radio" style="margin-bottom: 20px; padding-left: 10px;">
                            <label>
                                <input type="radio"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.organicStatus"
                                       value="NO"/>
                                {{'ADMIN_CUSTOMERS_ORGANIC_NO' | translate}}
                            </label>
                        </div>
                    </div>
                    <hr/>
                    <div class="form-group" ng-class="{
            'has-error': form.address.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_STREET' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.address" name="address" required
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.zip.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_ZIP' | translate}}</label>
                        <!--
                        To check for real german zip codes use this. I don't use it because there could be international customers
                        <input class="form-control" type="text" ng-model="customer.customer.zip" name="zip" required
                                require ng-pattern="/^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/"/>
                        -->
                        <input class="form-control" type="text" ng-model="edit.customer.zip" name="zip" required
                               ng-readonly="!edit.canEdit"
                               require ng-pattern="/^[0-9]{1,6}$/"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.city.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_CITY' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.city" name="city" required
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.country.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_COUNTRY' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.country" name="country"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="checkbox" style="margin-bottom: 15px">
                        <label style="font-weight: 700">
                            <input type="checkbox"
                                   ng-readonly="!edit.canEdit"
                                   ng-model="edit.customer.isOfficial"/>
                            {{'customer.isOfficial.label' | translate}}
                        </label>
                    </div>
                    <hr/>
                    <div class="form-group" ng-class="{
            'has-error': form.telephone.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_PHONE' | translate}}</label>
                        <!-- http://stackoverflow.com/a/6967885 -->
                        <input class="form-control" type="text" ng-model="edit.customer.telephone" name="telephone"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.mobilePhone.$invalid
            }">
                        <label class="control-label">{{'tenant.mobilePhone.label' | translate}}</label>
                        <!-- http://stackoverflow.com/a/6967885 -->
                        <input class="form-control" type="text" ng-model="edit.customer.mobilePhone" name="mobilePhone"
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.fax.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_FAX' | translate}}</label>
                        <!-- http://stackoverflow.com/a/6967885 -->
                        <input class="form-control" type="text" ng-model="edit.customer.fax" name="fax"
                               ng-readonly="!edit.canEdit"
                               require
                               ng-pattern="/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.email.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_EMAIL' | translate}}</label>
                        <input class="form-control" type="email" ng-model="edit.customer.email" name="email" required
                               ng-readonly="!edit.canEdit"/>
                    </div>
                    <!-- Mark as contractor -->
                    <div class="alert alert-warning">{{'customer.markAsContractorNotice' | translate}}</div>
                    <div class="checkbox" style="margin-bottom: 15px">
                        <label style="font-weight: 700">
                            <input type="checkbox"
                                   ng-readonly="!edit.canEdit"
                                   ng-model="edit.customer.isContractor"/>
                            {{'customer.isContractor.label' | translate}}
                        </label>
                    </div>
                </uib-tab>
                <uib-tab heading="{{'ADMIN_CUSTOMERS_TAB_CONTRACTING' | translate}}" ng-if="!edit.domainWachau">
                    <div class="form-group" ng-class="{'has-error': form.supplierIdentifier.$invalid}">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_BONITUR' | translate}}</label>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.classificationFull"/>
                                {{'ADMIN_CUSTOMERS_BONITUR' | translate}}
                            </label>
                        </div>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.classificationSingle"/>
                                {{'ADMIN_CUSTOMERS_BONITUR_EL' | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.customerIdentifier.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_CUSTOMER_IDENTIFIER' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.customerIdentifier"
                               name="customerIdentifier" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.supplier.$invalid
            }">
                        <label class="control-label">{{'tenant.supplier.label' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.supplier"
                               name="supplier" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.supplierIdentifier.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_SUPPLIER_IDENTIFIER' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.supplierIdentifier"
                               name="supplierIdentifier" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.commissionary.$invalid
            }">
                        <label class="control-label">{{'tenant.commissionary.label' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.commissionary"
                               name="commissionary" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_ENTRY_DATE' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   uib-datepicker-popup="dd.MM.yyyy"
                                   ng-model="edit.customer.entryDate"
                                   readonly="readonly"
                                   is-open="edit.entryDateOpen"
                                   type="text"
                                   show-button-bar="false"
                                   datepicker-options="{
                                datepickerMode: 'day',
                                minMode: 'day'
                    }"/>
                            <div class="input-group-btn">
                                <button class="btn btn-default"
                                        ng-click="edit.entryDateOpen = !edit.entryDateOpen">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_TERMINATION_DATE' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   uib-datepicker-popup="dd.MM.yyyy"
                                   ng-model="edit.customer.terminationDate"
                                   readonly="readonly"
                                   is-open="edit.terminationDateOpen"
                                   type="text"
                                   show-button-bar="false"
                                   datepicker-options="{
                                datepickerMode: 'day',
                                minMode: 'day'
                    }"/>
                            <div class="input-group-btn">
                                <button class="btn btn-default"
                                        ng-click="edit.terminationDateOpen = !edit.terminationDateOpen">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" ng-class="{'has-error': form.supplierIdentifier.$invalid}">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_BUSINESS_STATUS' | translate}}</label>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.lessor"/>
                                {{'ADMIN_CUSTOMERS_LESSOR' | translate}}
                            </label>
                        </div>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.supplierPartial"/>
                                {{'ADMIN_CUSTOMERS_SUPPLIER_PARTIAL' | translate}}
                            </label>
                        </div>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.supplierFull"/>
                                {{'ADMIN_CUSTOMERS_SUPPLIER_FULL' | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="form-group"
                         ng-class="{'has-error': form.businessSharesPieces.$invalid || form.businessSharesValue.$invalid}">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_BUSINESS_SHARES' | translate}}</label>
                        <div class="input-group" style="margin-bottom: 20px;">
                            <input class="form-control" type="number" ng-model="edit.customer.businessSharesPieces"
                                   name="businessSharesPieces" ng-readonly="!edit.canEdit"/>
                            <span class="input-group-addon">{{'ADMIN_CUSTOMERS_FORM_HELP_PIECES' | translate}}</span>
                        </div>
                        <div class="input-group">
                            <input class="form-control" type="number" ng-model="edit.customer.businessSharesValue"
                                   name="businessSharesValue" ng-readonly="!edit.canEdit"/>
                            <span class="input-group-addon">{{'ADMIN_CUSTOMERS_FORM_HELP_VALUE' | translate}}</span>
                        </div>
                    </div>
                    <div class="form-group"
                         ng-class="{
               'has-error': form.taxRate.$invalid
             }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_TAX_RATE' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control" type="number" ng-model="edit.customer.taxRate"
                                   name="taxRate" ng-readonly="!edit.canEdit"/>
                            <span class="input-group-addon">{{'ADMIN_CUSTOMERS_FORM_HELP_TAX' | translate}}</span>
                        </div>
                    </div>
                    <div class="form-group"
                         ng-class="{
               'has-error': form.taxRateDescription.$invalid
             }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_TAX_RATE_DESCRIPTION' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.taxRateDescription"
                               name="taxRateDescription" ng-readonly="!edit.canEdit"/>
                    </div>
                </uib-tab>
                <uib-tab heading="Vertragsdaten" ng-if="edit.domainWachau">
                    <div class="form-group" ng-class="{'has-error': form.supplierIdentifier.$invalid}">
                        <label class="control-label"
                               ng-if="!edit.domainWachau">{{'ADMIN_CUSTOMERS_ORGANIC_FARMING' | translate}}</label>
                        <label class="control-label" ng-if="edit.domainWachau">Bewirtschaftungsart</label>
                        <div class="radio" style="margin-bottom: 20px; padding-left: 10px;">
                            <label>
                                <input type="radio"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.organicStatus"
                                       value="NO"/>
                                {{'ADMIN_CUSTOMERS_ORGANIC_NO' | translate}}
                            </label>
                        </div>
                        <div class="radio" style="margin-bottom: 20px; padding-left: 10px;">
                            <label>
                                <input type="radio"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.organicStatus"
                                       value="TRANSITION"/>
                                {{'ADMIN_CUSTOMERS_ORGANIC_CONVERSION' | translate}}
                            </label>
                        </div>
                        <div class="radio" style="margin-bottom: 20px; padding-left: 10px;">
                            <label>
                                <input type="radio"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.organicStatus"
                                       value="YES"/>
                                {{'ADMIN_CUSTOMERS_ORGANIC' | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="form-group" ng-class="{'has-error': form.supplierIdentifier.$invalid}">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_BONITUR' | translate}}</label>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.classificationFull"/>
                                {{'ADMIN_CUSTOMERS_BONITUR' | translate}}
                            </label>
                        </div>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.classificationSingle"/>
                                {{'ADMIN_CUSTOMERS_BONITUR_EL' | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.customerIdentifier.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_CUSTOMER_IDENTIFIER' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.customerIdentifier"
                               name="customerIdentifier" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group" ng-class="{
            'has-error': form.supplierIdentifier.$invalid
            }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_SUPPLIER_IDENTIFIER' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.supplierIdentifier"
                               name="supplierIdentifier" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_ENTRY_DATE' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   uib-datepicker-popup="dd.MM.yyyy"
                                   ng-model="edit.customer.entryDate"
                                   readonly="readonly"
                                   is-open="edit.entryDateOpen"
                                   type="text"
                                   show-button-bar="false"
                                   datepicker-options="{
                                datepickerMode: 'day',
                                minMode: 'day'
                    }"/>
                            <div class="input-group-btn">
                                <button class="btn btn-default"
                                        ng-click="edit.entryDateOpen = !edit.entryDateOpen">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_TERMINATION_DATE' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   uib-datepicker-popup="dd.MM.yyyy"
                                   ng-model="edit.customer.terminationDate"
                                   readonly="readonly"
                                   is-open="edit.terminationDateOpen"
                                   type="text"
                                   show-button-bar="false"
                                   datepicker-options="{
                                datepickerMode: 'day',
                                minMode: 'day'
                    }"/>
                            <div class="input-group-btn">
                                <button class="btn btn-default"
                                        ng-click="edit.terminationDateOpen = !edit.terminationDateOpen">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" ng-class="{'has-error': form.supplierIdentifier.$invalid}">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_BUSINESS_STATUS' | translate}}</label>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.lessor"/>
                                {{'ADMIN_CUSTOMERS_LESSOR' | translate}}
                            </label>
                        </div>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.supplierPartial"/>
                                {{'ADMIN_CUSTOMERS_SUPPLIER_PARTIAL' | translate}}
                            </label>
                        </div>
                        <div class="checkbox" style="margin-bottom: 20px; padding-left: 10px">
                            <label>
                                <input type="checkbox"
                                       ng-readonly="!edit.canEdit"
                                       ng-model="edit.customer.supplierFull"/>
                                {{'ADMIN_CUSTOMERS_SUPPLIER_FULL' | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="form-group"
                         ng-class="{'has-error': form.businessSharesPieces.$invalid || form.businessSharesValue.$invalid}">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_BUSINESS_SHARES' | translate}}</label>
                        <div class="input-group" style="margin-bottom: 20px;">
                            <input class="form-control" type="number" ng-model="edit.customer.businessSharesPieces"
                                   name="businessSharesPieces" ng-readonly="!edit.canEdit"/>
                            <span class="input-group-addon">{{'ADMIN_CUSTOMERS_FORM_HELP_PIECES' | translate}}</span>
                        </div>
                        <div class="input-group">
                            <input class="form-control" type="number" ng-model="edit.customer.businessSharesValue"
                                   name="businessSharesValue" ng-readonly="!edit.canEdit"/>
                            <span class="input-group-addon">{{'ADMIN_CUSTOMERS_FORM_HELP_VALUE' | translate}}</span>
                        </div>
                    </div>
                    <div class="form-group"
                         ng-class="{
               'has-error': form.taxRate.$invalid
             }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_TAX_RATE' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control" type="number" ng-model="edit.customer.taxRate"
                                   name="taxRate" ng-readonly="!edit.canEdit"/>
                            <span class="input-group-addon">{{'ADMIN_CUSTOMERS_FORM_HELP_TAX' | translate}}</span>
                        </div>
                    </div>
                    <div class="form-group"
                         ng-class="{
               'has-error': form.taxRateDescription.$invalid
             }">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_TAX_RATE_DESCRIPTION' | translate}}</label>
                        <input class="form-control" type="text" ng-model="edit.customer.taxRateDescription"
                               name="taxRateDescription" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group"
                         ng-class="{
               'has-error': form.businessCreditAccount.$invalid
             }">
                        <label class="control-label">Konto Geschäftsguthaben</label>
                        <input class="form-control" type="text" ng-model="edit.customer.businessCreditAccount"
                               name="businessCreditAccount" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group"
                         ng-class="{
               'has-error': form.debitorsAccount.$invalid
             }">
                        <label class="control-label">Debitoren-Konto</label>
                        <input class="form-control" type="text" ng-model="edit.customer.debitorsAccount"
                               name="debitorsAccount" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group"
                         ng-class="{
               'has-error': form.loanAccountGroup2.$invalid
             }">
                        <label class="control-label">Darlehen-Konto Gruppe 2</label>
                        <input class="form-control" type="text" ng-model="edit.customer.loanAccountGroup2"
                               name="loanAccountGroup2" ng-readonly="!edit.canEdit"/>
                    </div>
                    <div class="form-group"
                         ng-class="{
               'has-error': form.loanAccountGroup3.$invalid
             }">
                        <label class="control-label">Darlehen-Konto Gruppe 3</label>
                        <input class="form-control" type="text" ng-model="edit.customer.loanAccountGroup3"
                               name="loanAccountGroup3" ng-readonly="!edit.canEdit"/>
                    </div>

                </uib-tab>
                <uib-tab heading="{{'ADMIN_CUSTOMERS_TAB_MODULE' | translate}}">
                    <div class="form-group">
                        <label class="control-label">{{'ADMIN_CUSTOMERS_MODULES' | translate}}</label>
                        <ul class="list-group well">
                            <li ng-repeat="module in edit.modules" class="list-group-item">
                                <div class="checkbox" ng-click="edit.removeModuleAlert()">
                                    <label>
                                        <input type="checkbox" ng-model="module.selected" ng-disabled="!edit.canEdit">
                                        {{module.translatedLabel}}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </uib-tab>
            </uib-tabset>
        </ng-form>
    </div>
</div>
