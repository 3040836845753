(function () {
    'use strict';

    class UsersEditCtrl {
        constructor($state, $stateParams, CrudApi, UserService, permissions, $q, ModuleService, DownloadApi, FileSaver) {
            let vm = this,
                promises = {},
                permissionIndex = {},
                reasonablePermissions = [
                    permissions.BASE_CAN_DO_MANAGEMENT,
                    permissions.BASE_CAN_DO_ORDERS
                ],
                emailResent = false;
            vm.canReadCostsPerHour = UserService.hasPerm(permissions.BASE_CAN_READ_COSTSPERHOUR);
            // Initial message
            vm.message = $stateParams.message;
            vm.canEdit = angular.isDefined($stateParams.id) && UserService.hasPerm(permissions.BASE_CAN_EDIT_USER) ||
                angular.isUndefined($stateParams.id) && UserService.hasPerm(permissions.BASE_CAN_CREATE_USER);
            vm.getSelected = getSelected;
            vm.getPermissionTooltip = (mod, permission) => ModuleService.getPermissionTooltip(mod, permission);
            vm.isCreate = angular.isUndefined($stateParams.id);
            vm.isPermissionsReasonable = isPermissionsReasonable;
            vm.setPermissionsSelected = setPermissionsSelected;

            vm.getSelectedUserColor = () => {
                if (vm.user && vm.user.color) {
                    return vm.user.color;
                }
                return '#ffffff';
            };

            vm.download = () => {
                DownloadApi
                    .get({
                        entity: 'users',
                        id: vm.user.id,
                        subentity: 'qrCode'
                    }).$promise.then((data) => {
                        console.log(data)
                    FileSaver.saveAs(data.blob, data.filename)
                })
            }

            // add wineArea options dependent from account
            vm.wineArea = [];

            // Add items to submenu
            if (vm.canEdit) {
                vm.submenu = {
                    items: [{
                        type: 'button',
                        text: 'BUTTON_SAVE',
                        click: () => {
                            let postData = angular.copy(vm.user);
                            postData.permissionIds = [];
                            vm.modules.forEach(mod => {
                                let perms = getSelected(mod.permissions);
                                postData.permissionIds.push(...perms.map(perm => perm.id));
                            });
                            CrudApi.save({
                                id: $stateParams.id,
                                entity: 'users'
                            }, postData, (data) => {
                                $state.go('users.edit', {
                                    id: data.id,
                                    message: 'savingSuccess'
                                }, {
                                    reload: true
                                });
                            }, (response) => {
                                if (response.status === 409) {
                                    vm.message = 'emailAlreadyExists';
                                } else if (response.status === 410) {
                                    vm.message = 'entityHasChanged';
                                } else {
                                    vm.message = 'savingError';
                                }
                            });
                        }
                    }, {
                        type: 'button',
                        text: 'RESEND_EMAIL_BUTTON',
                        hide: vm.isCreate,
                        isDisabled: () => {
                            return emailResent;
                        },
                        click: () => {
                            emailResent = true;
                            CrudApi.save({
                                id: $stateParams.id,
                                entity: 'users',
                                command: 'resendEmail'
                            }, null, angular.noop, () => {
                                emailResent = false;
                            });
                        }
                    }, {
                        type: 'button',
                        text: 'USER_QR_CODE_DOWNLOAD',
                        hide: vm.isCreate,
                        position: 'right',
                        isDisabled: () => {
                            return false;
                        },
                        click: () => {
                            vm.download();
                        }
                    }]
                };
            }

            // Initialize mainmenu items
            vm.mainmenu = {
                items: []
            };
            promises.moduleServiceInitialized = ModuleService.initialize();
            promises.modules = CrudApi.query({
                entity: 'modules',
                id: UserService.user.customerUuid
            }).$promise;

            if (vm.isCreate) {
                promises.user = {
                    active: true,
                    objectType: 'User'
                };
            } else {
                promises.user = CrudApi.get({
                    id: $stateParams.id,
                    entity: 'users'
                }).$promise;
            }

            $q.all(promises).then(({user, modules}) => {
                if (vm.isCreate) {
                    // Main menu item "new worker"
                    vm.mainmenu.items.push({
                        label: 'USER_NEW',
                        state: 'users.create'
                    });
                } else {
                    // Set password to null
                    user.password = null;
                    user.loginTag = null;
                    vm.mainmenu.items.push({
                        label: user.lastname + ', ' + user.firstname,
                        translate: false,
                        state: 'users.edit',
                        params: {
                            id: user.id
                        }
                    });
                }
                vm.user = user;
                console.log(modules)
                modules.forEach(mod => {
                    ModuleService.translatePermissionLabels(mod);
                    mod.permissions = ModuleService.sortPermissions(mod);
                    mod.permissions.forEach(perm => {
                        perm.selected = angular.isArray(vm.user.permissionIds) && vm.user.permissionIds.indexOf(perm.id) !== -1;
                        permissionIndex[getQualifiedPermissionName(mod, perm)] = perm;
                    });
                });
                ModuleService.translateModuleLabels(modules);
                vm.modules = ModuleService.sortModules(modules);
                vm.modules = ModuleService.filterModulesOnUniqueness(modules);
                vm.isRegionalManager = ModuleService.userHasPermissions(vm.user, [permissions.ADMIN_CAN_BE_REGIONAL_MANAGER]);
                console.log('vm.modules:', vm.modules)
                if (UserService.getVcToken().accountUuid === 'edd2c849-adea-44a1-9d0d-4e9ab4302e55') { // GWF
                    vm.wineArea = [
                        'Abt Degen Weintal',
                        'Alzenauer Weinregion',
                        'Bayerischer Bodensee',
                        'Churfranken',
                        'Frankens Saalestück',
                        'Main Himmelreich',
                        'Main Süden',
                        'Mittelfränkische Bocksbeutelstraße',
                        'Mittelmain',
                        'Schwanberger Land',
                        'Tauberfranken',
                        'Volkacher Mainschleife',
                        'Weinpanorama Steigerwald',
                        'Weinparadies',
                        'WWK Lese 1',
                        'WWK Lese 2'];
                }
            }, () => {
                vm.message = 'loadingError';
            });

            function getSelected(objects) {
                return objects.filter(obj => obj.selected);
            }

            function setPermissionsSelected(perms, selected) {
                perms.forEach(permission => permission.selected = selected);
            }

            function getQualifiedPermissionName(mod, permission) {
                return mod.label + '.' + permission.label;
            }

            function isPermissionsReasonable() {
                // If we don't have any permissions yet, we can't really know if we are resonable
                let reasonable = Object.keys(permissionIndex).length === 0;
                for (let i = 0; i < reasonablePermissions.length && !reasonable; i++) {
                    const permissionLabel = reasonablePermissions[i],
                        permission = permissionIndex[permissionLabel];
                    reasonable = permission && permission.selected;
                }
                return reasonable;
            }
        }
    }

    UsersEditCtrl.$inject = ['$state', '$stateParams', 'CrudApi', 'UserService', 'permissions', '$q', 'ModuleService', 'DownloadApi', 'FileSaver'];

    /**
     * @ngdoc object
     * @name users.edit.controller:UsersEditCtrl
     *
     * @description
     *
     */
    angular
        .module('users.edit')
        .controller('UsersEditCtrl', UsersEditCtrl);
}());
