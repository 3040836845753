<sub-menu items="list.submenu.items"></sub-menu>
<main-menu></main-menu>

<div class="worker-list-wrapper">
    <div class="ad-block" ng-if="list.hasAdvertisement">
    </div>
    <div class="worker-list">
        <main-message message="list.message" fluid="'nested'"></main-message>
        <div class="table-responsive" ng-if="list.items.length">
            <table class="table table-hover">
                <thead>
                <th ng-click="list.sort('firstname')">
                    {{'FIRSTNAME' | translate}}
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'firstname'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th ng-click="list.sort('lastname')">
                    {{'LASTNAME' | translate}}
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'lastname'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th ng-click="list.sort('email')">
                    {{'EMAIL' | translate}}
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'email'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th class="text-center" ng-click="list.sort('isActive')">
                    {{'USER_STATUS' | translate}}
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'isActive'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th>
                    {{'USER_ASSIGNED_TASKS' | translate}}
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'assignedTaskCount'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th class="text-center">
                    {{'USER_WORKING' | translate}}
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'working'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th class="text-center">
                    {{'USER_SIGNED_IN' | translate}}
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'signedIn'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th class="text-center">
                    {{'USER_GPS' | translate}}
                </th>
                <th class="text-center"
                    ng-if="list.canDelete">{{'DELETE' | translate}}
                </th>
                </thead>
                <tbody>
                <tr ng-repeat="item in list.items"
                    ng-class="{
                'success': item.inUsage
              }"
                    ui-sref="users.edit({
                id:item.id
              })">
                    <td>{{item.firstname}}</td>
                    <td>{{item.lastname}}</td>
                    <td>{{item.email}}</td>
                    <td class="text-center">
              <span class="glyphicon"
                    ng-class="{
                      'glyphicon-ok': item.isActive,
                      'glyphicon-remove': !item.isActive
                    }"></span>
                    </td>
                    <td class="text-center">{{item.assignedTaskCount}}</td>
                    <td class="text-center">
              <span class="glyphicon"
                    ng-class="{
                      'glyphicon-ok': item.working,
                      'glyphicon-remove': !item.working
                    }"></span>
                    </td>
                    <td class="text-center">
              <span class="glyphicon"
                    ng-class="{
                      'glyphicon-ok': item.signedIn,
                      'glyphicon-remove': !item.signedIn
                    }"></span>
                    </td>
                    <td class="text-center">
                        <button class="btn btn-link"
                                ng-click="$event.stopPropagation()"
                                popover-trigger="'mouseenter'"
                                popover-placement="bottom"
                                popover-title="{{'USER_GPS_PERMISSION_TITLE' | translate}}"
                                uib-popover-html="list.createGPSTemplate(item)">
                            <span class="glyphicon glyphicon-map-marker"></span>
                        </button>
                    </td>
                    <td class="text-center"
                        ng-if="list.canDelete">
                        <button class="btn btn-link"
                                ng-click="$event.stopPropagation(); list.delete(item)">
                            <span class="glyphicon glyphicon-trash"></span>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <ul uib-pagination
            total-items="list.total"
            ng-if="list.total > list.itemsPerPage"
            ng-model="list.page"
            items-per-page="list.itemsPerPage"
            ng-change="list.paginate()"
            max-size="list.maxSize"
            boundary-links="true"
            previous-text="&lsaquo;"
            next-text="&rsaquo;"
            first-text="&laquo;"
            last-text="&raquo;">
        </ul>
    </div>
    <div class="ad-block" ng-if="list.hasAdvertisement">
        <div class="ad-jovinbo">
            <a class="ad-logo-wrapper" href="https://jovinbo.com/" target="_blank">
                <img class="image-responsive ad-logo" src="images/jovinbo-icon.png" alt="Jovinbo"/>
            </a>
            <div class="ad-entry" ng-repeat="item in list.jobListings">
                <span class="ad-entry-title">{{item.title}}</span>
                <a class="ad-entry-button" href="{{item.link}}" target="_blank">
                    {{'jovinbo.toJobListing' | translate}}
                </a>
            </div>
            <div class="ad-load-more-button-wrapper">
                <a class="ad-load-more-button" href="https://jovinbo.com/" target="_blank">
                    {{'jovinbo.loadMore' | translate}}
                </a>
            </div>
        </div>
    </div>
</div>
