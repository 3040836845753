<ng-form class="form-group order-plant-protection"
         name="plantProtectionForm"
         style="display: block;"
         ng-controller="OrderTypePlantProtectionCtrl as additional">
  <div class="alert alert-warning">{{'PLANT_PROTECTION_WARNING' | translate}}</div>

  <!-- ARTICLES -->
  <ng-form class="form-group"
           ng-class="{
             'has-error': plantProtectionArticlesForm.$invalid
           }"
           name="plantProtectionArticlesForm"
           style="display: block;">
    <label class="control-label">{{'PLANT_PROTECTION_ARTICLES' | translate}}</label>
    <div class="row"
         style="margin-bottom: 5px;"
         ng-repeat="article in edit.additionalData.articles">
      <div class="col-xs-7">
        <input class="form-control"
               type="text"
               readonly
               ng-model="article.article.label" />
      </div>
        <div class="col-xs-2">
            <input class="vc-checkbox"
                   name="basic_effort"
                   id="basic_effort"
                   title="Basisaufwand berücksichtigen."
                   type="checkbox"
                   ng-disabled="true"
                   ng-readonly="!edit.canEdit"
                   ng-model="article.useBaseExpenditure"
            />
            <span class="ng-binding">{{'ARTICLE_BASIC_EFFORT' | translate}}</span>
      </div>

      <div class="col-xs-3">
        <div class="input-group">
          <input class="form-control"
                 type="number"
                 required
                 ng-disabled="true"
                 ng-model="article.amountPerArea" />
          <span class="input-group-addon"
                style="min-width: 40px;">{{article.article.unit}} / {{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="row">
        <a class="col-xs-12" ng-href="/ui/order/edit/{{edit.order.id}}">{{'order.ui.info.redirectToBetaUI' | translate}}</a>
    </div>
  </ng-form>
</ng-form>
